import styled, {css} from "styled-components";
import React from "react";
import Button from "../Elemnts/Button";


export const StyledBar = styled.div`
  width: 95%;
  height: 80px;
  padding-top: 10px;
  font-family: ${({theme}) => theme.font.Lobster};
  z-index: 0;

  ul {
    width: auto;
    counter-reset: step;
    display: flex;
    list-style: none;
  }

  ${({theme}) => theme.media.desktop} {
    width: 80%;
    height: 90px;
    margin: 0 auto;

    ul {
      width: auto;
      counter-reset: step;
      display: flex;
      list-style: none;
    }
  }
}
`
export const StyledBarLi = styled(({counters, ...props}) => <li {...props}/>)`


  ${({counters}) => css`
    font-family: ${({theme}) => theme.font.family.Montserrat};
    width: 33.3%;
    position: relative;
    z-index: 0;
    display: grid;
    place-items: center;
    margin-right: 16px;
    transition: all .2s ease-in-out;

    &:before {
      content: counter(step);
      counter-increment: step;
      width: 35px;
      height: 35px;
      display: grid;
      place-items: center;
      border-radius: 25px;
      text-align: center;
      z-index: 0;
      margin-bottom: 5px;
      background-color: white;
      border: 1px solid black;
      font-weight: 300;
      transition: all 1.2s;
    }

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: black;
      position: absolute;
      top: 33%;
      left: -49%;
      z-index: -11111;
      opacity: 0.1;
      transition: all 1.2s;
    }

    &:first-child:after {
      content: none;
    }

    &:not(:nth-child(n+${counters})):before {
      background-color: ${({theme}) => theme.primary};
      border: 2px solid black;
      font-weight: 800;
      margin-bottom: 5px;
      transform: scale(1.1);
    }

    &:not(:nth-child(n+${counters})):after {
      opacity: 1;
    }
  `
  }`


export const StyledEmptyDesc = styled.div`
      width: 100vw;
      height: calc(100vh - 320px);
      min-height: 300px;
      display: grid;
      place-items: center;

      h2 {
        height: 50px;
      }
      ${({theme}) => theme.media.desktop} {
        width: 100%;
        height: 100%;
        min-height: 300px;
        display: grid;
        place-items: center;

      }
    `
export const StyledDivFooterWizard = styled.div`
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;


      ${({theme}) => theme.media.desktop} {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
      } `
export const StyledButtonFooterProgresBar = styled(Button)`
      width: 120px;
      margin: 0 0 30px 0;
    `

export const StyledButtonFooterFinishButton=styled(StyledButtonFooterProgresBar)`
      text-decoration: none;
      display: grid;
      place-items: center;
    `