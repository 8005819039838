import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import PropTypes from "prop-types";
import Inpost from "../Inpost/Inpost"
import "./StyleModals.css"

const StyledModal = styled.div`
  width: 100%;
  z-index: 88888888;
  position: relative;
  display: none;

  ${({theme}) => theme.media.desktop} {
    width: 1000px;
    border: 1px solid red;
  }
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  top: 0;
  right: 15px;
  background: none;
  padding-left: 6px;

  z-index: 99999999;
  border: 2px solid black;
  border-radius: 10%;
  position: absolute;

  &:focus {
    outline: 2px auto black;
    -moz-outline-radius: 5px;

  }

  ${({theme}) => theme.media.tablet} {
    width: 50px;
    height: 50px;

    background: none;
    z-index: 99999999;
    padding-left: 10px;
    margin-top: 35px;
    margin-right: 10px;
    margin-left: 0;

    &:focus {
      outline: 2px auto black;
      -moz-outline-radius: 5px;

    }
  }

  ${({theme}) => theme.media.desktop} {
    width: 40px;
    height: 40px;
    margin: 0;
    top: 0;
    right: 0;
    background: none;
    z-index: 9999999999;
    padding-left: 7px;
    transform: scale(0.85);

    &:focus {
      outline: 2px auto black;
      -moz-outline-radius: 5px;

    }
  }
`;
const ButtonInner = styled.div`
  position: relative;


  ::before, ::after {
    content: '';
    position: absolute;
    display: flex;
    width: 25px;
    height: 2px;
    left: 0;
    background-color: black;
    transition: transform .25s .15s ease-in-out;
  }

  ::before {
    top: -7px;
    transform: translateY(7px) rotate(45deg);
  }

  ::after {
    top: 7px;
    left: 0;
    transform: translateY(-7px) rotate(-45deg);
  }
`;
const StyledInner = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  p {
    font-family: ${({theme}) => theme.font.family.Montserrat};
    text-align: center;
    margin-top: 70px;
  }
`;


/*
const customStyles = {
    content: {
        width: "100%",
        height: "90%",
        top: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-10%",
        transform: "translate(-50%, -50%)",
        zIndex: "99999999999"
    }
};*/

const ModalInpost = ({isModalInpostOpen, setModalInpostOpen}) => {
    Modal.setAppElement('#___gatsby')
    const toogleModalInpostOpenClose = () => {
        setModalInpostOpen(false);
    };

    return (
        <StyledModal>
            <Modal
                isOpen={isModalInpostOpen}
                onRequestClose={toogleModalInpostOpenClose}

                className="Modal"

            >
                <StyledInner>

                    <Button onClick={toogleModalInpostOpenClose}>
                        <ButtonInner/>
                    </Button>

                    <Inpost/>
                </StyledInner>
            </Modal>
        </StyledModal>
    );
};
ModalInpost.propTypes = {
    isModalInpostOpen: PropTypes.bool,
    setModalInpostOpen: PropTypes.func,


}

ModalInpost.defaultProps = {
    isModalOpen: false,

}
export default ModalInpost
