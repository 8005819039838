import React, {useContext} from 'react';
import orderContext from "../OrderContext";
import useHoney from "../../utlis/useHoney";
import formatMoney from 'src/utlis/formatMoney';
import DeliverAndPayment from "./DeliverAndPayment";
import {
    StyledWrapper,
    StyledImg,
    StyledInner,
    StyledTable,
    StyledTbody,
    Counter,
    StyledButton,
    Quality,
    StyledProduct,
    StyledLink,
    Value,
} from "./HoneyOrder.styles"
import PropTypes from "prop-types";


export default function HoneyOrder({flavored, isOrder}) {
    const {orders} = useContext(orderContext);
    const [order] = orders;
    const {addToQuality, removeFromQuality, removeFromOrder} = useHoney({flavored});


    return (<StyledWrapper>

        <StyledInner>
            {!isOrder ? <h2 className="left">Zawartość Twojego koszyka</h2> : <h3 margin>Przyjeliśmy twoje zamówienie</h3>}
            <StyledTable>
                <thead>
                <tr>
                    <th>Produkct</th>
                    <th>ilość</th>
                    <th>cena</th>
                    <Value as='th'>wartość</Value>
                    {!isOrder ? <th>usuń</th> : null}
                </tr>

                </thead>
                {order.map((singleOrder, index) => {
                    const honey = flavored.find((honey) => honey.id === singleOrder.id);
                    return (

                        <StyledTbody key={singleOrder.id}>
                            <tr>
                                <td>
                                    <StyledProduct>
                                        <StyledImg src={honey.fotoOffer.url}
                                                   alt={honey.fotoOffer.url}/>

                                        <StyledLink to={`/miody/${honey.slug}`}>
                                            <h3>{honey.title} - {honey.capacity[0].choicecapacity}</h3>
                                        </StyledLink>
                                    </StyledProduct>

                                </td>
                                <td><Counter>
                                    {!isOrder ? <StyledButton disabled={order.quality < 0}
                                                              onClick={() => removeFromQuality({id: singleOrder.id})}>-</StyledButton> : null}
                                    <Quality>{singleOrder.quality}</Quality>
                                    {!isOrder ? <StyledButton
                                        onClick={() => addToQuality({id: singleOrder.id})}>+</StyledButton> : null}
                                </Counter>
                                </td>
                                <td>{formatMoney(honey.price)}</td>
                                <Value>{formatMoney(honey.price * singleOrder.quality)}</Value>
                                {!isOrder ? <td>

                                    <StyledButton
                                        type="button"
                                        className="remove"
                                        title={`Remove ${singleOrder.size} from Order`}
                                        onClick={() => removeFromOrder(index)}
                                    >
                                        &times;
                                    </StyledButton>
                                </td> : null}
                            </tr>
                        </StyledTbody>

                    );
                })}
            </StyledTable>
            <DeliverAndPayment isOrder={isOrder} flavored={flavored}/>

        </StyledInner>
    </StyledWrapper>);
}

HoneyOrder.propTypes = {
    flavored: PropTypes.array.isRequired,
    isOrder: PropTypes.bool
};
