import React, {useContext} from "react";
import orderContext from "../OrderContext";
import {useWizard} from "react-use-wizard";
import {Link} from 'gatsby';
import useHoney from "../../utlis/useHoney";
import {StyledDivFooterWizard, StyledButtonFooterProgresBar, StyledButtonFooterFinishButton} from "src/components/ProgressBar/ProgresBar.styles";

const FooterWizard = ({flavored}) => {
    const {isValidForm, handleSetCheck, checkDelivery, toogleModalOpen, orders} = useContext(orderContext);
    const [order] = orders;
    const {clearOrder} = useHoney(flavored);
    const twoFunctionsinOne = async () => {
        await handleSetCheck(true);
        await toogleModalOpen();
    }

    const SendMailAndGo = async () => {
        await handleSetCheck(true);
        await nextStep();
    }
    const {
        nextStep,
        previousStep,
        isLoading,
        isLastStep,
        isFirstStep,
        activeStep
    } = useWizard();


    return (
        <StyledDivFooterWizard>

            <StyledButtonFooterProgresBar
                ownWidth="140"
                onClick={() => previousStep()}
                disabled={isLoading || isFirstStep}
            >Wstecz
            </StyledButtonFooterProgresBar>
            {activeStep === 0 ? (<>{!checkDelivery ? (
                <StyledButtonFooterProgresBar ownWidth="140" onClick={() => toogleModalOpen()}
                                              disabled={isLoading || isLastStep || order.length === 0}>
                    Dalej
                </StyledButtonFooterProgresBar>) : (
                <StyledButtonFooterProgresBar ownWidth="140" onClick={() => nextStep()}
                                              disabled={isLoading || isLastStep}>
                    Dalej
                </StyledButtonFooterProgresBar>)}</>) : null
            }

            {activeStep === 1 ? (<>
                {activeStep === 1 && isValidForm === false ? (<>
                    <StyledButtonFooterProgresBar onClick={() => twoFunctionsinOne()}
                                                  disabled={isLoading || isLastStep}>
                        Dalej
                    </StyledButtonFooterProgresBar></>) : (
                    <StyledButtonFooterProgresBar onClick={() => SendMailAndGo()} disabled={isLoading || isLastStep}>
                        Dalej
                    </StyledButtonFooterProgresBar>)}
            </>) : null}
            {activeStep === 2 ?
                <StyledButtonFooterFinishButton onClick={()=> clearOrder()} as={Link} to="/" disabled={isLoading || isLastStep}>
                    Koniec
                </StyledButtonFooterFinishButton> : null
            }

        </StyledDivFooterWizard>
    )
}

export default FooterWizard;