import {useWizard} from "react-use-wizard";
import React from "react";
import { StyledBarLi, StyledBar} from "./ProgresBar.styles";

const HeaderWizard = () => {
    const {activeStep} = useWizard();
    const counters=activeStep+2;

    return (
        <div>
            <StyledBar >
                <ul>
                    <StyledBarLi counters={counters}>Koszyk</StyledBarLi>
                    <StyledBarLi counters={counters}>Dane</StyledBarLi>
                    <StyledBarLi counters={counters}>Potwierdzenie</StyledBarLi>

                </ul>
            </StyledBar>

        </div>
    );
};

export default HeaderWizard;
