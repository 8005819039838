import React, {useContext, useEffect, useState} from 'react';
import {
    StyledDeliverAndPayment,
    StyledBottom,
    StyledButton,
    StyledDelivery,
    StyledInput,
    StyledLinks,
    StyledListElement,
    StyledSearchInpost,
    StyledSummary
} from "./HoneyOrder.styles";
import formatMoney from "../../utlis/formatMoney";
import calculateOrderTotal from "../../utlis/calculateOrderTotal";
import orderContext from "../OrderContext";
import PropTypes from "prop-types";


const DeliverAndPayment = ({flavored, isOrder}) => {
    const {
        orders,
        deliver,
        handleSetDelivery,
        handleCheckDelivery,
        selectedpoint,
        handleSetPayment,
        payment,
        toogleModalInpostOpen
    } = useContext(orderContext);
    const [order] = orders;
    const initialFormState = {name: '', price: 0, payment: ''};
    const [isChecked, setIsChecked] = useState(initialFormState)
    const [isPay, setIsPay] = useState(false)

    useEffect(() => {
        if (isChecked.name === '' && deliver.name !== '') {
            setIsChecked({...isChecked, name: deliver.name})
        }
        if (isChecked.price === 0 && deliver.price !== 0) {
            setIsChecked({...isChecked, price: deliver.price})
        }
    }, [deliver]);

    const handleChange = (e) => {
        let {value} = e.target;
        if (e.target.type === 'number') {
            value = parseFloat(e.target.value);
        }
        setIsChecked({
            ...isChecked, [e.target.name]: value, price: e.target.id,
        })
        handleSetDelivery({name: isChecked.name, price: isChecked.price})
    }

    const handleChangePayment = (e) => {
        let {value} = e.target;
        if (e.target.type === 'number') {
            value = parseFloat(e.target.value);
        }
        setIsChecked({
            ...isChecked, [e.target.name]: value,
        })
        handleSetDelivery({payment: isChecked.payment})
    }

    useEffect(() => {
        if (isChecked.name !== '') {
            handleSetDelivery(isChecked);
        }
        if (isChecked.payment !== '') {
            setIsPay(true)
            handleSetPayment(isChecked);
        }
        if (isChecked.name === '') {
            handleCheckDelivery(false)
            return;
        }
        if (isChecked.name === 'Inpost paczkomat' && selectedpoint.length > 0 && isPay === true) {
            handleCheckDelivery(true)
        }
        if (isChecked.name !== 'Inpost paczkomat' && isChecked.name.length > 0 && isPay === true) {
            handleCheckDelivery(true)
        }
        if (isChecked.name === '' || isPay === false) {
            handleCheckDelivery(false)
        }
    }, [isChecked, isPay, selectedpoint]);


    return (
        <StyledDeliverAndPayment>
            {!isOrder ? <StyledBottom>

                <StyledDelivery isOrder>
                    <h3>Wybierz dostawe:</h3>

                    <StyledListElement>
                        <label><StyledInput type="radio" id="18.99" name="name"
                                            value="Inpost paczkomat"
                                            checked={deliver.name === 'Inpost paczkomat'}
                                            onChange={handleChange}/>
                            Inpost Paczkomat</label>
                        <p>{formatMoney(18.99)}</p>
                        {deliver.name === 'Inpost paczkomat' ? (<>
                            <StyledSearchInpost>
                                <input type="text" id="fname" name="fname" placeholder="Wybrany paczkomat"
                                       value={selectedpoint}/>
                            </StyledSearchInpost>
                            <StyledLinks>
                                {selectedpoint.length > 0 ? (<StyledButton Big onClick={toogleModalInpostOpen}>zmień
                                    paczkomat</StyledButton>) : (<StyledButton Big onClick={toogleModalInpostOpen}>wybierz
                                    paczkomat</StyledButton>)}
                            </StyledLinks></>) : null}
                    </StyledListElement>

                    <StyledListElement>
                        <label><StyledInput type="radio" id="21,00" name="name" value="Inpost kurier"
                                            checked={deliver.name === 'Inpost kurier'}
                                            onChange={handleChange}/>
                            Inpost Kurier </label>
                        <p>{formatMoney(21)}</p>
                    </StyledListElement>

                    <StyledListElement>
                        <label><StyledInput type="radio" id="0,00" name="name" value="Odbiór osobisty"
                                            checked={deliver.name === 'Odbiór osobisty'} onChange={handleChange}/>
                            Odbiór osobisty</label>
                        <p>{formatMoney(0)}</p>
                    </StyledListElement>

                </StyledDelivery>

                <StyledDelivery isOrder>
                    <h3>Wybierz płatność:</h3>
                    <StyledListElement>
                        <label><StyledInput type="radio" name="payment" value="Przelew"
                                            checked={isChecked.payment === 'Przelew' || payment.payment === 'Przelew'}
                                            onChange={handleChangePayment}/>
                            Przelew</label>

                    </StyledListElement>
                    <StyledListElement>
                        <label><StyledInput type="radio" name="payment" value="Za pobraniem"
                                            checked={isChecked.payment === 'Za pobraniem' || payment.payment === 'Za pobraniem'}
                                            onChange={handleChangePayment}/>
                            Za pobraniem</label>

                    </StyledListElement>

                </StyledDelivery>


            </StyledBottom> : null}
            <StyledSummary>
                <h3 isOrder>Do zapłaty: {formatMoney(calculateOrderTotal(order, flavored, isChecked.price))}</h3>

                {!isOrder ? null : <p isOrder>Metoda dostawy: {deliver.name} - {(deliver.price)}</p>}
                {!isOrder ? null : <p last>Metoda zapłaty: {deliver.payment}</p>}
            </StyledSummary>
        </StyledDeliverAndPayment>
    )
}
export default DeliverAndPayment;

DeliverAndPayment.propTypes = {
    flavored: PropTypes.array.isRequired,
    isOrder: PropTypes.bool
};
