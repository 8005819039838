import styled, {css} from "styled-components";

export const StyledForm = styled.form`
  font-family: ${({theme}) => theme.font.family.Montserrat};
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
z-index:0;
  input {
    height: 50px;
    border: none;
    z-index:0;

  }  
  ${({theme}) => theme.media.desktop} {
    font-family: ${({theme}) => theme.font.family.Montserrat};
    width: 80%;
    margin: 0 auto;
    padding-bottom: 50px;

    input {
      height: 50px;
      border: none;

    }
  }
`
export const StyledPerson = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 20px;
    margin: 25px 0;
  }
`

export const StyledInner = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  position: relative;

  input {
    width: 90vw;
    height: 60px;
    border: 2px solid ${({theme}) => theme.orange};
    border-radius: 5px;
    z-index: 0;


    &::placeholder {
      padding: 5px;
    }

    ${({Big}) =>
            Big &&
            css`
              height: 120px;
              &::placeholder {
                position: relative;
                top: 10px;
                color: red;
                font-size: 16px;
              }
            ;
            `};
  }

  p {
    width: auto;
    height: 20px;
    padding: 0 5px;
    position: absolute;
    top: -20px;
    left: 10px;
    color: black;
    background-color: white;
    font-family: ${({theme}) => theme.font.family.Montserrat};
    font-size: ${({theme}) => theme.font.size.xxs};
    z-index: ${({isModalOpen}) => isModalOpen ? '0':'2'};
  }
;

  .error {
    width: auto;
    z-index: ${({isModalOpen}) => isModalOpen ? '0':'3'};
    color: red;
  }
;

  ${({theme}) => theme.media.desktop} {


    input {
      padding-left: 5px;
      width: ${({customWidth}) => (customWidth ? `${customWidth}px` : 'auto')};
      min-width: ${({customWidth}) => (customWidth ? `${customWidth}px` : '400px')};
      height: 60px;
    }

  }
`;
export const ErrorP = styled.p`
width: auto;
z-index: ${({isModalOpen}) => isModalOpen ? '0':'3'};
color: red;
    `
export const StyledRadio = styled.form`
  max-width: 410px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const P = styled.p`
  width: auto;
  padding: 0 10px;`

export const StyledSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;

  ${({theme}) => theme.media.desktop} {
    width: auto;
    justify-content: flex-start;
  }
`;

export const OneLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({top}) => top ? '25px' : 0};
  &:first-child{
    margin-top:10px; 
  }
  ${({theme}) => theme.media.desktop} {
    display: grid;
    max-width: 380px;
    grid-template-columns: ${({flex}) => (flex ? `${flex}` : '1fr 1fr')};
    justify-content: center;
    gap:10px;
    place-items: center;
    margin-top:0;
    &:first-child{
      margin-top:0;
    }
  }
`