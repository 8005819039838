import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {StyledForm, StyledInner, StyledPerson, StyledRadio, P, StyledSection, OneLine} from "./Form.styles";
import orderContext from "../OrderContext";
import useDidMountEffect from "../../utlis/useDidMountEffect";
import useHoney from "../../utlis/useHoney";
import useForms from "../../utlis/useForms"

export default function Form({flavored, isModalOpen}) {

    const {values, updateValue} = useForms();

    const {OwnSubmits} = useHoney({flavored, values});
    const [isCompany, setIsCompany] = useState(false);
    const {handleSetValidForm, checkForm} = useContext(orderContext);
    const {register, handleSubmit,  setValue ,formState: {errors, isValid,  isValidating}} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            Firstname: "",
            Lastname: "",
            Email: "",
            Mobilenumber: "",
            Ulica: "",
            Nr: "",
            Code: "",
            City: "",
            nip: ""
        }
    });
    /*console.log('isValid',isValid);*/
    const formRef = useRef(null);

    const toggleCompany = (value) => {
        if (value === 'yes') {
            setIsCompany(true);
        } else {
            setIsCompany(!isCompany)
        }
    }
    useEffect(() => {

        handleSetValidForm(isValid)
    }, [isValid]);

    useDidMountEffect(async () => {
        if (isValid) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))

        }
    }, [checkForm]);


    return (
      <>
          <StyledForm id='my-form' ref={formRef} isModalOpen={isModalOpen} onSubmit={() => handleSubmit(OwnSubmits())}>
              <h3>Twoje dane</h3>
              <StyledRadio onChange={(e) => toggleCompany(e.target.value)}>
                  <ErrorMessage errors={errors} name="Company"/>

                  <input checked={isCompany === false} {...register("Company")} type="radio" value='no'/>
                  <P>Osoba prywatna</P>

                  <input checked={isCompany === true} {...register("Company", {
                      required: "Wybierz rodzaj",
                  })} type="radio" value='yes'/>
                  <P>Firma</P>

              </StyledRadio>
              <StyledPerson>
                  <StyledSection>
                      <StyledInner isModalOpen={isModalOpen}>

                          <p>Imie</p>
                          <input type="text" placeholder="Imie" {...register("Firstname", {
                              required: "Podaj imie", minLength: 3,
                          })} onChange={(e) => {updateValue(e); setValue('Firstname', e.target.value);}}/>

                          <ErrorMessage
                            errors={errors}
                            name="Firstname"
                            render={({message}) => <p className="error">{message}</p>}
                          />
                      </StyledInner>
                  </StyledSection>
                  <StyledSection>
                      <StyledInner isModalOpen={isModalOpen}>
                          <p>Nazwisko </p>
                          <input type="text" placeholder="Nazwisko" {...register("Lastname", {
                              required: "Podaj nazwisko",
                              minLength: 3,

                          })} onChange={(e) => {updateValue(e); setValue('Lastname', e.target.value);}}/>
                          <ErrorMessage errors={errors} name="Lastname"
                                        render={({message}) => <p className="error">{message}</p>}/>
                      </StyledInner>
                  </StyledSection>
                  <StyledSection>
                      <StyledInner isModalOpen={isModalOpen}>
                          <p>E-mail</p>
                          <input type="text" placeholder="Email" {...register("Email", {
                              required: "Podaj email",
                              pattern: /^\S+@\S+$/i
                          })} onChange={(e) => {updateValue(e); setValue('Email', e.target.value);}}/>
                          <ErrorMessage errors={errors} name="Email"
                                        render={({message}) => <p className="error">{message}</p>}/>
                      </StyledInner>
                  </StyledSection>
                  <StyledInner isModalOpen={isModalOpen}>
                      <p>Numer telefonu</p>
                      <input type="tel" placeholder="Numer telefonu" {...register("Mobilenumber", {
                          required: "Podaj numer telefonu",
                          minLength: {
                              value: 7,
                              message: "Podany numer telefonu jest za krótki"
                          },
                          maxLength: 12,
                      })} onChange={(e) => {updateValue(e); setValue('Mobilenumber', e.target.value);}}/>
                      <ErrorMessage errors={errors} name="Mobilenumber"
                                    render={({message}) => <p className="error">{message}</p>}/>
                  </StyledInner>
                  <OneLine top flex={"1.5fr 0.5fr"}>
                      <StyledSection>
                          <StyledInner isModalOpen={isModalOpen} customWidth={280}>

                              <p>Ulica</p>
                              <input
                                type="text"
                                placeholder="Ulica"
                                {...register("Ulica", {
                                    required: "Podaj Ulicę"
                                })}
                                onChange={(e) => {updateValue(e); setValue('Ulica', e.target.value);}}/>

                              <ErrorMessage
                                errors={errors}
                                name="Ulica"
                                render={({message}) => <p className="error">{message}</p>}
                              />
                          </StyledInner>
                      </StyledSection>

                      <StyledSection>
                          <StyledInner isModalOpen={isModalOpen} customWidth={115}>

                              <p>Nr</p>
                              <input
                                type="text"
                                placeholder="Nr"
                                {...register("Nr", {
                                    required: "Podaj numer",
                                    minLength: 1,
                                    maxLength: 4,
                                })} onChange={(e) => {updateValue(e); setValue('Nr', e.target.value);}}/>

                              <ErrorMessage
                                errors={errors}
                                name="Nr"
                                render={({message}) => <p className="error">{message}</p>}
                              />
                          </StyledInner>
                      </StyledSection>
                  </OneLine>
                  <OneLine flex={"0.5fr 1.5fr"}>
                      <StyledSection>
                          <StyledInner isModalOpen={isModalOpen} customWidth={120}>

                              <p>Kod pocztowy</p>
                              <input
                                type="text"
                                placeholder="Kod pocztowy"
                                {...register("Code", {
                                    required: "Podaj kod pocztowy",
                                    minLength: {
                                        value: 4,
                                        message: "Podany kod jest za krótki"
                                    },
                                    maxLength: 6,
                                })} onChange={(e) => {updateValue(e); setValue('Code', e.target.value);}}/>

                              <ErrorMessage
                                errors={errors}
                                name="Code"
                                render={({message}) => <p className="error">{message}</p>}
                              />
                          </StyledInner>
                      </StyledSection>

                      <StyledSection>
                          <StyledInner isModalOpen={isModalOpen} customWidth={280}>

                              <p>Miejscowość</p>
                              <input
                                type="text"
                                placeholder="Miasto"
                                {...register("City", {
                                    required: "Podaj miasto",
                                })}  onChange={(e) => {updateValue(e); setValue('City', e.target.value);}}/>
                              <ErrorMessage
                                errors={errors}
                                name="City"
                                render={({message}) => <p className="error">{message}</p>}
                              />
                          </StyledInner>
                      </StyledSection>
                  </OneLine>
                  {isCompany ? (<><StyledInner isModalOpen={isModalOpen}>
                      <StyledSection>

                          <p>Nip</p>
                          <input type="text" placeholder="nip" {...register("nip", {
                              required: "Podaj nip",
                              minLength: 10,
                              maxLength: 14
                          })} onChange={(e) => {updateValue(e); setValue('nip', e.target.value);}}/>
                          <ErrorMessage
                            errors={errors}
                            name="nip"
                            render={({message}) => <p className="error">{message}</p>}
                          />
                      </StyledSection>
                  </StyledInner>

                      <StyledInner isModalOpen={isModalOpen}>
                          <StyledSection>
                              <p>Nazwa firmy</p>
                              <input type="text" placeholder="Nazwa firmy" {...register("nazwa", {
                                  required: "Podaj nazwe firmy",
                                  minLength: 3,
                                  maxLength: 50
                              })}  onChange={(e) => {updateValue(e); setValue('Nazwa firmy', e.target.value);}}/>
                              <ErrorMessage
                                errors={errors}
                                name="nazwa"
                                render={({message}) => <p className="error">{message}</p>}
                              />
                          </StyledSection>
                      </StyledInner></>) : null}
              </StyledPerson>
          </StyledForm>
      </>

    );
}
