import React, {useContext} from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import {useWizard, Wizard} from "react-use-wizard";
import Form from "../components/Form/Form";
import orderContext from "../components/OrderContext";
import Modal from "../components/Modal/Modal"
import HeaderWizard from "src/components/ProgressBar/HeaderWizard"
import FooterWizard from "src/components/ProgressBar/FooterWizard"
import HoneyOrder from "../components/HoneyOrder/HoneyOrder";
import ModalInpost from "../components/Modal/Modal_Inpost";
import {StyledEmptyDesc} from "../components/ProgressBar/ProgresBar.styles";

const StyledWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  ${({theme}) => theme.media.desktop} {
    width: 70%;
    height: auto;
    min-height: calc(100vh - 325px);
    margin: 0 auto;
  }
`

const Step1 = ({ data}) => {
    const {handleStep} = useWizard();
    const { isModalOpen, toogleModalOpen, setModalOpen, toogleModalInpostOpen, isModalInpostOpen, setModalInpostOpen} = useContext(orderContext);
    const flavored = data.flavored.nodes;

    handleStep(() => {

    });
    return <>
        <Modal modalContext={"Prosimy wybrac dostawę"} isModalOpen={isModalOpen} setModalOpen={setModalOpen} onClick={toogleModalOpen}/>
        <ModalInpost isModalInpostOpen={isModalInpostOpen} setModalInpostOpen={setModalInpostOpen} onClick={toogleModalInpostOpen}/>
        <HoneyOrder setModalOpen={setModalOpen} flavored={flavored} isOrder={false}/>
    </>;
};


const Step2 = ({data}) => {
    const flavored = data.flavored.nodes;
    const {isModalOpen, toogleModalOpen, setModalOpen,} = useContext(orderContext);
    const {handleStep} = useWizard();

    handleStep(() => {

    });
    return <>

        <Modal modalContext={"Prosimy podać brakujące dane do dostawy"} isModalOpen={isModalOpen}
               setModalOpen={setModalOpen} onClick={toogleModalOpen}/>
        <Form isModalOpen={isModalOpen} flavored={flavored}/>

    </>
};

const Step3 = ({data}) => {
    const flavored = data.flavored.nodes;
    const {handleStep} = useWizard();
const StyledH2=styled.h2`
font-family: ${({theme}) => theme.font.family.Montserrat};
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
`

    handleStep(() => {

    });

    return <><StyledH2>Dziekujemy za zlozenie zamowienia</StyledH2>
        <HoneyOrder flavored={flavored} isOrder={true}/>
    </>;
};


// eslint-disable-next-line react/prop-types
const ShopPage = ({data}) => {

    const flavored = data.flavored.nodes;
    const {orders} = useContext(orderContext);
    const [order] = orders;


    return (

        <StyledWrapper>

            {order.length === 0 ? (<StyledEmptyDesc><h2>Twój koszyk jest pusty</h2></StyledEmptyDesc>) :
                (<Wizard header={<HeaderWizard />}
                         footer={<FooterWizard flavored={flavored}/>}>
                    <Step1 number={1} data={data}/>
                    <Step2 number={2} data={data}/>
                    <Step3 number={3} data={data}/>
                </Wizard>)}

        </StyledWrapper>
    )
}
export default ShopPage
Step1.propTypes = {
    data: PropTypes.object,
};
Step2.propTypes = {
    data: PropTypes.object,
};
Step3.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    query {
        flavored : allDatoCmsOffer{
            nodes {
                capacity {
                    choicecapacity
                }
                description
                id
                slug
                fotoOffer {
                    url
                    fluid {
                        src
                    }
                }
                title
                typeOfMany
                price
            }
        }
    }
`;
