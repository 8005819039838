import { useState } from 'react';

export default function useForms(defaults) {
    const [values, setValues] = useState(defaults);
    /*const requiredFields = ['Firstname', 'Lastname', 'Email', 'Mobilenumber'];

    for (const field of requiredFields) {
        console.log(`Checking that ${field} is good`);
        if (!values[field]) {

               console.log('Oops! You are missing the ${field} field')

            }
        }*/

      function updateValue(e) {
        // chech is a number and convert
        let { value } = e.target;
        if (e.target.type === 'number') {
            value = parseInt(e.target.value);
        }
        setValues({
            // copy exisiting values into it
            ...values,
            // update the new value that changed
            [e.target.name]: value,
        });
    }
    return { values, updateValue };
}
